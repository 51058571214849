import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from "react-native";
import utils, { InteractionManager } from "../Misc/utils"; // Import your utility module
import { useNavigation } from "@react-navigation/native"; // Import useNavigation
import {
  DesktopMainStackNavigationProp,
  MobileMainHomeNavigationProp,
} from "../Navigation/navigationTypes";
import moment from "moment";
import { like, save, ai } from "../assets/icons"; // Import the SVG components
import { isMobile } from "react-device-detect";
import * as Icon from "react-native-feather";
import { changeUrl } from "../App";

const interactionManager = InteractionManager.getInstance();

export interface CellType {
  cell: CellProps;
  source?: string;
}
export interface CellProps {
  id: string;
  title: string;
  imageUrl: string;
  full_explanation: string;
  links: any;
  created_at: any;
}

const Cell: React.FC<CellType> = React.memo(({ cell }) => {
  const { id, title, imageUrl, full_explanation, links, created_at } = cell;
  const isMobileOS =
    Platform.OS === "ios" || Platform.OS === "android" || isMobile;
  const desktopNavigation = useNavigation<DesktopMainStackNavigationProp>();
  const mobileNavigation = useNavigation<MobileMainHomeNavigationProp>();
  const [liked, setLiked] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const timeFromNow = (timestamp: any) => {
    return moment(timestamp).fromNow();
  };

  const handleLikePress = async () => {
    if (!liked) {
      interactionManager.add({
        post_id: id,
        type: "like",
        data: { reaction: "liked" },
      });
      console.log("Liked:", title);
      setLiked(true);
    } else {
      interactionManager.add({
        post_id: id,
        type: "like",
        data: { reaction: "unliked" },
      });
      console.log("Unliked:", title);
      setLiked(false);
    }
  };

  const handleSavePress = async () => {
    if (!saved) {
      interactionManager.add({
        post_id: id,
        type: "save",
        data: { reaction: "saved" },
      });
      console.log("Saved:", title);
      setSaved(true);
    } else {
      interactionManager.add({
        post_id: id,
        type: "save",
        data: { reaction: "unsaved" },
      });
      console.log("Unsaved:", title);
      setSaved(false);
    }
  };
  const navigateToInnerCell = (source: undefined | string) => {
    changeUrl(`posts/${cell.id}`);
    if (isMobileOS) {
      mobileNavigation.navigate("InnerCell", { cell, source });
    } else {
      desktopNavigation.navigate("InnerCell", { cell, source });
    }
  };
  // Inside your Cell component
  const handlePress = () => {
    const source = "home";
    navigateToInnerCell(source);
    interactionManager.add({
      post_id: id,
      type: "post_click",
      data: { source: source },
    });
  };
  return (
    <TouchableOpacity
      style={styles.everything}
      activeOpacity={1}
      onPress={handlePress}
    >
      <View style={styles.allContainer}>
        <Text style={styles.title} numberOfLines={3} ellipsizeMode="tail">
          {title}
        </Text>
        <View style={styles.container}>
          <View style={styles.content}>
            <Text
              style={styles.part_explanation}
              numberOfLines={5}
              ellipsizeMode="tail"
            >
              {full_explanation}
            </Text>
            <Text style={styles.timeAgo}>{timeFromNow(created_at)}</Text>
          </View>

          <View style={styles.imageAndButtonsContainer}>
            <Image source={{ uri: imageUrl }} style={styles.image} />
            <View style={styles.buttonsContainer}>
              <TouchableOpacity
                style={[styles.buttonContainer]}
                onPress={handleLikePress}
              >
                {liked ? (
                  <Icon.ThumbsUp
                    stroke="white"
                    fill="black"
                    width={34}
                    height={34}
                    strokeWidth={1.6}
                  />
                ) : (
                  <Icon.ThumbsUp
                    stroke="grey"
                    width={30}
                    height={30}
                    strokeWidth={1.6}
                  />
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.buttonContainer]}
                onPress={handleSavePress}
              >
                {saved ? (
                  <Icon.Bookmark
                    stroke="black" //"#FFD000"
                    width={30}
                    height={30}
                    strokeWidth={2}
                    fill="black" //"#FFD000"
                  />
                ) : (
                  <Icon.Bookmark
                    stroke="grey"
                    width={30}
                    height={30}
                    strokeWidth={1.6}
                  />
                )}
              </TouchableOpacity>
              {/* <TouchableOpacity
              style={[styles.buttonContainer]}
              onPress={handleAskAIPress}
            >
              <Icon.HelpCircle
                stroke="grey"
                width={28}
                height={28}
                strokeWidth={1.6}
              />
            </TouchableOpacity> */}
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  everything: {
    padding: 16,
    // alignItems: "center",

    paddingTop: 40, // adjust this value to change the top and bottom spacing
    paddingBottom: 30, // adjust this value to change the top and bottom spacing
    // paddingVertical: 0,

    paddingHorizontal: 18, // adjust this value to change the left and right spacing
    backgroundColor: "white",
    borderColor: "#ccc",
    borderBottomWidth: 1,
  },
  allContainer: {
    flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "space-between",

    // justifyContent: "space-between",
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    flex: 2,
  },
  title: {
    fontSize: 16,
    paddingRight: 5,
    paddingLeft: 8,
    paddingBottom: 12,
    fontWeight: "bold",
    // textAlign: "left", // This will center the text
    fontFamily: "ComfortaaMed",
    // marginBottom: 8,
  },
  part_explanation: {
    paddingLeft: 8,
    paddingRight: 5,
    textAlign: "left", // This will center the text
    fontSize: 15,
    //fontFamily: "Futura",
    color: "#888",
  },
  image: {
    //flex: 0.8, // adjust this value to change the size of the image
    aspectRatio: 1,
    borderRadius: 8,
    marginVertical: 8,
    height: 100,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around", // This will distribute the space around the buttons evenly
    marginTop: 8,
    alignItems: "center", // This will center the buttons vertically
    width: 100,
  },

  buttonContainer: {
    width: 40, // specify the width
    height: 40, // specify the height
    alignItems: "center",
    justifyContent: "center",
    aspectRatio: 1,
    padding: 0,
  },

  imageAndButtonsContainer: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center", // Add this to align the image and buttons
  },
  timeAgo: {
    paddingLeft: 8,
    paddingTop: 18,
    color: "#888",
    fontSize: 12,
  },
});

export default Cell;
