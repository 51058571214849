// auth-context.tsx
import React, { useState, ReactNode } from "react";

export interface UserProps {
  user_id: string;
  username: string;
  displayName: string;
  avatarUrl: string;
  matrixAccessToken?: string;
  email?: string;
}
export interface AuthContextProps {
  userData: UserProps;
  isLoggedIn: boolean;
  isLoading: boolean;
  setUserData: (userData: UserProps) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  logOut: () => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  userData: {
    user_id: "",
    username: "",
    displayName: "",
    avatarUrl: "",
    matrixAccessToken: undefined,
    email: undefined,
  },
  isLoggedIn: false,
  isLoading: true,
  setUserData: () => {},
  setIsLoggedIn: () => {},
  setIsLoading: () => {},
  logOut: () => {},
});
