import React, { useState, useContext } from "react";
import utils from "../Misc/utils"; // Import your utils module
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation, useFocusEffect } from "@react-navigation/native"; // Import useNavigation
import {
  DesktopMainStackNavigationProp,
  MobileMainHomeNavigationProp,
} from "../Navigation/navigationTypes";
import { changeUrl } from "../App";

import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  TouchableOpacity,
} from "react-native";
import { AuthContext } from "./auth-context"; // Import your AuthContext
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import { isMobile } from "react-device-detect";

const LoginPage: React.FC = () => {
  // add a redirect so we know where to redirect after login

  const isMobileOS =
    Platform.OS === "ios" || Platform.OS === "android" || isMobile;
  const { isLoggedIn, setIsLoggedIn, setUserData } = useContext(AuthContext);

  const [errorMessage, setError] = useState("");

  const mobileNavigation = useNavigation<MobileMainHomeNavigationProp>();
  const desktopNavigation = useNavigation<DesktopMainStackNavigationProp>();

  const handleGoogleLogin = async (payload: any) => {
    try {
      console.log(payload);
      const response = await utils.auth({
        action: "google_login",
        data: payload,
      });

      if (response && response.ok) {
        // Login was successful
        const data = await response.json();
        console.log("Login successful", data);
        await AsyncStorage.setItem("token", data.token);

        setIsLoggedIn(true);
        console.log("setting is logged in to true");
        console.log(isLoggedIn);
        setUserData(data.userData);
        changeUrl("");
        isMobileOS
          ? mobileNavigation.navigate("Home")
          : desktopNavigation.navigate("Home");
      } else {
        // Login failed, display an error message
        const errorData = await response?.json();
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Error during Google login", error);
    }
  };
  useFocusEffect(
    React.useCallback(() => {
      if (isLoggedIn) {
        changeUrl("");
        isMobileOS
          ? mobileNavigation.navigate("Home")
          : desktopNavigation.navigate("Home");
      }
    }, [isLoggedIn])
  );
  return (
    // <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
    >
      <View>
        {/* <Text style={styles.heading}>Log In </Text> */}
        <Text
          style={{
            fontSize: 34,
            marginBottom: 20,
            textAlign: "center",
            alignItems: "center",
            fontFamily: "ComfortaaMed",
            fontWeight: 400,
          }}
        >
          Connect to Gate
        </Text>
        <View style={styles.googleSignIn}>
          <GoogleOAuthProvider clientId="204588103385-n5a2hfm0b9tjklv8a8si78l5tfkua245.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={handleGoogleLogin}
              onError={() => {
                console.log("Login Failed");
              }}
              shape="pill"
              // size="large"
              type="standard"
              theme="outline"
              text="signin_with"
              locale="en"
              containerProps={{
                style: {},
              }}
            />
          </GoogleOAuthProvider>
        </View>
        {/* <TextInput
          style={styles.input}
          placeholder="Email or Username"
          value={identifier}
          onChangeText={(text) => setIdentifier(text)}
        />
        <TextInput
          style={styles.input}
          placeholder="Password"
          secureTextEntry
          value={password}
          onChangeText={(text) => setPassword(text)}
        />
        <TouchableOpacity style={styles.button} onPress={handleLogin}>
          <Text style={styles.buttonText}>Log In</Text>
        </TouchableOpacity>
        {errorMessage ? ( // Display the error message conditionally
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : null}
        <Text style={styles.registerText}>
          Don't have an account?{" "}
          <Text style={{ color: "blue" }} onPress={navigateToRegister}>
            Register
          </Text>
        </Text> */}
      </View>
    </KeyboardAvoidingView>
    // </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: 16,
  },
  heading: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: "center",
  },
  googleSignIn: {
    marginVertical: 16,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },

  input: {
    height: 40,
    borderColor: "#ccc",
    borderWidth: 1,
    marginBottom: 12,
    paddingHorizontal: 8,
  },
  button: {
    backgroundColor: "#007BFF",
    paddingVertical: 12,
    borderRadius: 8,
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    fontSize: 18,
  },
  registerText: {
    textAlign: "left",
    marginTop: 16,
  },
  errorMessage: {
    color: "red", // Style the error message as needed
    textAlign: "center",
    marginTop: 8,
  },
});

export default LoginPage;
