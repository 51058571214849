import React from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  Linking,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { isMobile } from "react-device-detect";
import * as Icon from "react-native-feather";
import {
  DesktopMainStackNavigationProp,
  MobileMainHomeNavigationProp,
} from "../Navigation/navigationTypes";

const PCAgentPage = () => {
  const isMobileOS =
    Platform.OS === "ios" || Platform.OS === "android" || isMobile;
  const styles = isMobileOS ? mobileStyles : desktopStyles;
  const mobileNavigation = useNavigation<MobileMainHomeNavigationProp>();
  const desktopNavigation = useNavigation<DesktopMainStackNavigationProp>();

  const handleDownload = () => {
    Linking.openURL(
      "https://github.com/Gate-Platform/PC-Agent/releases/download/release/PC.Agent_1.0.0_x64-setup.exe"
    );
  };
  const handleSourceCode = () => {
    Linking.openURL("https://github.com/Gate-Platform/PC-Agent");
  };

  const VideoComponent = () => {
    if (Platform.OS === "web") {
      return (
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/97tUynaJusY"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    }
    return null;
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Personal Computer Agent</Text>
      <View style={styles.descriptionContainer}>
        <Text style={styles.description}>
          PC Agent is an AI that addresses key limitations of current chatbots.
          It uses screen context and audio transcription to understand your
          computer environment, to better aid with tasks
        </Text>
      </View>

      <View style={styles.videoContainer}>
        <VideoComponent />
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={styles.sourceButton}
          onPress={handleSourceCode}
        >
          <Icon.Github color="white" strokeWidth={2} />
          <Text style={styles.sourceButtonText}>Source Code</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.downloadButton}
          onPress={handleDownload}
        >
          <Icon.Download color="white" strokeWidth={2} />
          <Text style={styles.downloadButtonText}>Download Beta</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.comingSoonContainer}>
        <Icon.Clock color="#666" scale={0.8} strokeWidth={1.5} />
        <Text style={styles.comingSoon}>More features coming soon</Text>
      </View>
    </View>
  );
};

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    // backgroundColor: "#ffffff",
    alignItems: "center",
    width: "50%",
  },
  title: {
    fontSize: 32,
    fontWeight: "600",
    marginBottom: 30,
    color: "#333",
    fontFamily: "Comfortaa",
  },
  descriptionContainer: {
    backgroundColor: "#f8f8f8",
    borderRadius: 8,
    padding: 20,
    marginBottom: 30,
    width: "100%",
    // maxWidth: 600,
  },
  description: {
    fontSize: 16,
    color: "#333",
    lineHeight: 24,
    fontFamily: "ComfortaaMed",
  },
  videoContainer: {
    width: "100%",
    aspectRatio: 16 / 9,
    marginBottom: 30,
    borderRadius: 8,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  video: {
    flex: 1,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  sourceButton: {
    backgroundColor: "#4a4a4a",
    paddingVertical: 10,
    paddingHorizontal: 18,
    borderRadius: 8,
    // marginRight: 10,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 8,
  },
  sourceButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "500",
    marginLeft: 10,
    paddingRight: 2,
    fontFamily: "Comfortaa",
  },
  downloadButton: {
    backgroundColor: "#333",
    paddingVertical: 10,
    paddingHorizontal: 18,
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 8,
  },
  downloadButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
    marginLeft: 10,
    paddingRight: 2,
    fontFamily: "Comfortaa",
  },
  comingSoonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  comingSoon: {
    fontSize: 14,
    color: "#666",
    fontFamily: "Comfortaa",
    marginLeft: 6,
  },
});

const mobileStyles = StyleSheet.create({
  ...baseStyles,
  container: {
    ...baseStyles.container,
    // paddingTop: 40, // Accounting for mobile status bar
  },
  videoContainer: {
    ...baseStyles.videoContainer,
    // width: "100%",
  },
});

const desktopStyles = StyleSheet.create({
  ...baseStyles,
  container: {
    ...baseStyles.container,
    // maxWidth: 800,
    marginHorizontal: "auto",
  },
  videoContainer: {
    ...baseStyles.videoContainer,
    width: "95%",
  },
});

export default PCAgentPage;
