// Navigator.tsx
import React, { useState, useEffect, useContext } from "react";
import {
  BottomTabBarProps,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import AIChat from "../Components/AIChat";
import * as Icon from "react-native-feather";
import ChatPage from "../Components/ChatPage";
import UserPage from "../User/UserPage";
import HomePage from "../Components/HomePage"; // Import your MainScreen component
import LoginPage from "../Auth/LoginPage"; // Import your LoginPage component
import InnerCell from "../Posts/InnerCell";
import CreatePost from "../User/CreatePost";
import { chats, home, profile } from "../assets/icons";
import PCAgentPage from "../Components/PCAgent";
import Chat from "../Components/DesktopChat";
import BlankPage from "../Components/BlankPage";

import {
  View,
  Image,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  GestureResponderEvent,
} from "react-native";
import { AuthContext, UserProps } from "../Auth/auth-context";
import { Linking } from "react-native";
import TopBar from "../Misc/DesktopTopbar";
import SideBar from "../Misc/DesktopSidebar";
import Cell, { CellProps } from "../Posts/Cell"; // Import your Cell component
import {
  EventArg,
  NavigationContainer,
  useNavigation,
  useNavigationState,
} from "@react-navigation/native";
import {
  MobileBottomTabParams,
  MobileBottomTabNavigationProp,
  DesktopMainStackNavigationProp,
  DesktopMainStackParams,
  MobileMainHomeParams,
  MobileMainHomeNavigationProp,
} from "./navigationTypes";
import { post } from "../Misc/utils";
import { use } from "marked";
import { changeUrl } from "../App";
import ResearchPage from "../Components/Research";
import DesktopChat from "../Components/DesktopChat";
const BottomTabNav = createBottomTabNavigator<MobileBottomTabParams>();

const MobileHomeStackNav = createStackNavigator<MobileMainHomeParams>();

const MobileHomeStackNavigator: React.FC = () => {
  const stackNavigation = useNavigation<MobileMainHomeNavigationProp>();
  // In your main App component or a component that only mounts once
  React.useEffect(() => {
    Linking.getInitialURL().then((initialUrl) => {
      if (initialUrl) {
        const pathname = convertUrl(initialUrl);

        // Log the path
        console.log("Path: ", pathname);
        navigateUrlMobileStack(pathname, stackNavigation);
      }
    });
  }, []);
  return (
    <MobileHomeStackNav.Navigator initialRouteName="Home">
      <MobileHomeStackNav.Screen
        name="Home"
        component={HomePage}
        options={{ headerShown: false }}
      />
      <MobileHomeStackNav.Screen
        name="InnerCell"
        component={InnerCell}
        options={{ headerShown: false }}
      />

      <MobileHomeStackNav.Screen
        name="Login"
        component={LoginPage}
        options={{ headerShown: false }}
      />
      <MobileHomeStackNav.Screen
        name="Users"
        component={UserPage}
        options={{ headerShown: false }}
      />
      <MobileHomeStackNav.Screen
        name="Chat"
        component={DesktopChat}
        options={{ headerShown: false }}
      />
      <MobileHomeStackNav.Screen
        name="Research"
        component={ResearchPage}
        options={{ headerShown: false }}
      />
      <MobileHomeStackNav.Screen
        name="CreatePost"
        component={CreatePost}
        options={{ headerShown: false }}
      />
      <MobileHomeStackNav.Screen
        name="PCAgentPage"
        component={PCAgentPage}
        options={{ headerShown: false }}
      />
    </MobileHomeStackNav.Navigator>
  );
};

const MobileMainNavigator: React.FC = () => {
  const mobileNavigation = useNavigation<MobileBottomTabNavigationProp>();
  const stackNavigation = useNavigation<MobileMainHomeNavigationProp>();

  const { userData, isLoggedIn, isLoading } = useContext(AuthContext);

  const styles = StyleSheet.create({
    loginButton: {
      flexDirection: "row",
      backgroundColor: "black", // Set the background color of the button
      padding: 6,
      borderRadius: 4, // Round the corners
      marginRight: 20,
    },
    loginButtonText: {
      color: "white", // Set the text color
      fontSize: 16, // Set the font size
      fontWeight: "bold", // Make the text bold
      fontFamily: "Comfortaa",
      marginTop: 4,
      marginHorizontal: 10,
    },
  });

  const handleEventPress = (
    event: GestureResponderEvent | EventArg<"tabPress", true, undefined>,
    url: string,
    page: any
  ) => {
    // Prevent default action
    event.preventDefault();

    // Call your function
    changeUrl(url);

    // Navigate to the screen
    mobileNavigation.navigate(page);
  };
  const routeName = useNavigationState((state) => {
    return state?.routes[state?.index]?.name;
  });

  const handleLoginClick = () => {
    console.log("Login clicked");
    changeUrl(`login`);

    stackNavigation.navigate("Login");
  };
  // In your main App component or a component that only mounts once
  React.useEffect(() => {
    Linking.getInitialURL().then((initialUrl) => {
      if (initialUrl) {
        const pathname = convertUrl(initialUrl);

        // Log the path
        console.log("Path: ", pathname);
        navigateUrlMobileTab(pathname, mobileNavigation, {
          userData,
          isLoggedIn,
          isLoading,
        });
      }
    });
  }, []);

  return (
    <BottomTabNav.Navigator
      initialRouteName="Main"
      screenOptions={{
        headerRight: () =>
          !isLoading &&
          routeName != "Login" &&
          !isLoggedIn && (
            <TouchableOpacity
              style={styles.loginButton}
              onPress={handleLoginClick}
            >
              <Icon.LogIn color={"white"} />
              <Text style={styles.loginButtonText}>Login</Text>
            </TouchableOpacity>
          ),

        tabBarHideOnKeyboard: true,
        tabBarStyle:
          isLoggedIn && !isLoading ? { height: 55 } : { display: "none" },
        tabBarActiveTintColor: "black",
        tabBarShowLabel: true,
        headerTitle: () => (
          <TouchableOpacity onPress={(e) => handleEventPress(e, "", "Home")}>
            <Text
              style={{
                fontFamily: "Comfortaa",
                fontSize: 24,
                fontWeight: "500",
                marginLeft: 4,
                color: "black",
              }}
            >
              Gate
            </Text>
          </TouchableOpacity>
        ),
        tabBarLabelStyle: {
          //fontFamily: "Comfortaa",
          fontSize: 10,

          color: "black",
        },
      }}
    >
      {/* <BottomTabNav.Screen
        name="AI"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Icon.Disc
                stroke="black"
                width={28}
                height={28}
                strokeWidth={2}
              />
            ) : (
              <Icon.Disc
                stroke="gray"
                width={28}
                height={28}
                strokeWidth={1.5}
              />
            ),
        }}
        component={AIChat}
        listeners={{
          tabPress: (e) => handleEventPress(e, "ai", "AI"),
        }}
      /> */}
      {/* <Tab.Screen
        name="Info"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Icon.Inbox
                stroke="black"
                width={28}
                height={28}
                strokeWidth={2}
              />
            ) : (
              <Icon.Inbox
                stroke="gray"
                width={28}
                height={28}
                strokeWidth={1.5}
              />
            ),
        }}
        component={BlankPage}
      /> */}
      <BottomTabNav.Screen
        name="Chat"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Icon.MessageCircle
                stroke="black"
                width={28}
                height={28}
                strokeWidth={2}
              />
            ) : (
              <Icon.MessageCircle
                stroke="gray"
                width={28}
                height={28}
                strokeWidth={1.5}
              />
            ),
        }}
        component={Chat}
      />

      <BottomTabNav.Screen
        name="Main"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Icon.Home
                stroke="black"
                width={28}
                height={28}
                strokeWidth={2}
              />
            ) : (
              <Icon.Home
                stroke="gray"
                width={28}
                height={288}
                strokeWidth={1.5}
              />
            ),
        }}
        component={MobileHomeStackNavigator}
        listeners={{
          tabPress: (e) => handleEventPress(e, "", "Home"),
        }}
      />
      <BottomTabNav.Screen
        name="User"
        options={{
          tabBarIcon: ({ focused }) =>
            focused ? (
              <Icon.User
                stroke="black"
                width={28}
                height={28}
                strokeWidth={2}
              />
            ) : (
              <Icon.User
                stroke="gray"
                width={28}
                height={28}
                strokeWidth={1.5}
              />
            ),
        }}
        component={UserPage}
        listeners={{
          tabPress: (e) => {
            // Prevent default action
            e.preventDefault();

            if (!isLoggedIn && !isLoading) {
              handleLoginClick();
            }

            // Call your function
            changeUrl(`users/${userData.user_id}`);

            // Navigate to the screen
            mobileNavigation.navigate("User", {});
          },
        }}
      />
    </BottomTabNav.Navigator>
  );
};
const convertUrl = (initialUrl: string) => {
  // Create a new URL object
  const url = new URL(initialUrl);

  return url.pathname;
};

const DesktopMainStackNav = createStackNavigator<DesktopMainStackParams>();

const DesktopMainNavigator: React.FC = () => {
  const desktopNavigation = useNavigation<DesktopMainStackNavigationProp>();

  useEffect(() => {
    // this will call oneffect
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handlePopState = () => {
    // Handle your page change here
    const pathname = convertUrl(window.location.href);
    console.log("page changed to: ", pathname);
    navigateUrlDesktop(pathname, desktopNavigation);
  };

  // In your main App component or a component that only mounts once
  React.useEffect(() => {
    Linking.getInitialURL().then((initialUrl) => {
      if (initialUrl) {
        const pathname = convertUrl(initialUrl);

        // Log the path
        console.log("Path: ", pathname);
        navigateUrlDesktop(pathname, desktopNavigation);
      }
    });
  }, []); // Empty dependency array ensures this runs only once

  return (
    <>
      <View style={{ flex: 1 }}>
        <TopBar />
        <View style={{ flex: 1, flexDirection: "row" }}>
          <SideBar />

          <DesktopMainStackNav.Navigator
            screenOptions={{
              headerShown: false,
            }}
            initialRouteName="Home"
          >
            <DesktopMainStackNav.Screen name="User" component={UserPage} />
            <DesktopMainStackNav.Screen
              name="InnerCell"
              component={InnerCell}
            />
            <DesktopMainStackNav.Screen
              name="CreatePost"
              component={CreatePost}
            />
            <DesktopMainStackNav.Screen name="Home" component={HomePage} />
            <DesktopMainStackNav.Screen
              name="Research"
              component={ResearchPage}
            />
            <DesktopMainStackNav.Screen
              name="PCAgentPage"
              component={PCAgentPage}
            />
            <DesktopMainStackNav.Screen name="Chat" component={Chat} />
            <DesktopMainStackNav.Screen name="Login" component={LoginPage} />
            <DesktopMainStackNav.Screen name="AI" component={AIChat} />
          </DesktopMainStackNav.Navigator>
          {/* {isLoggedIn && <DesktopChat />} */}
        </View>
      </View>
    </>
  );
};
export function navigateUrlDesktop(
  url_path: string,
  navigation: DesktopMainStackNavigationProp
) {
  // Split the path by "/"

  if (!url_path || url_path.trim() === "" || url_path.trim() === "/") {
    changeUrl("");
    navigation.navigate("Home");
    console.log("going Home as path is none");
    return;
  }
  const pathParts = url_path.split("/");
  console.log("should be navigating: ", pathParts);

  switch (pathParts[1].toLowerCase()) {
    case "posts":
      console.log("going to posts");
      const post_id = pathParts[2];
      console.log(pathParts);
      if (post_id) {
        console.log("posts: ", post_id);
        changeUrl(`posts/${post_id}`);

        navigation.navigate("InnerCell", {
          post_id,
        });
      }

      break;
    case "users":
      const user_id = pathParts[2];
      if (user_id) {
        changeUrl(`users/${user_id}`);

        console.log("user", user_id);
        navigation.navigate("User", {
          passed_user_id: user_id,
        });
      }
      break;
    case "chat":
      changeUrl("chat");
      navigation.navigate("Chat");
      break;
    case "login":
      changeUrl("login");
      navigation.navigate("Login");
      break;
    case "ai":
      changeUrl("ai");
      navigation.navigate("AI");
      break;
    case "research":
      if (!pathParts[2]) {
        changeUrl("research");
        navigation.navigate("Research");
      } else if ((pathParts[2] = "pc-agent")) {
        changeUrl(`research/pc-agent`);
        navigation.navigate("PCAgentPage");
      } else {
        changeUrl("research");
        navigation.navigate("Research");
      }

      break;
    default:
      changeUrl("");
      navigation.navigate("Home");
      break;
  }
}
export function navigateUrlMobileTab(
  url_path: string,
  tabNavigation: MobileBottomTabNavigationProp,
  context: { userData: UserProps; isLoggedIn: boolean; isLoading: boolean }
) {
  // Split the path by "/"
  if (!url_path || url_path.trim() === "" || url_path.trim() === "/") {
    changeUrl("");
    tabNavigation.navigate("Main");
    console.log("going Home as path is none");
    return;
  }
  const pathParts = url_path.split("/");
  console.log("should be navigating: ", pathParts);

  switch (pathParts[1].toLowerCase()) {
    case "users":
      const user_id = pathParts[2];
      if (context.isLoading) {
        console.log("STILL LOADING IN NAVIGATION!!!!");
      }
      if (user_id == context.userData.user_id && context.isLoggedIn) {
        changeUrl(`users/${user_id}`);

        console.log("user", user_id);
        tabNavigation.navigate("User", { passed_user_id: user_id });
      }
      break;
    case "chat":
      changeUrl("chat");
      tabNavigation.navigate("Chat");
      break;
    // default:
    //   changeUrl("");
    //   tabNavigation.navigate("Main");
    //   break;
  }
}
export function navigateUrlMobileStack(
  url_path: string,
  navigation: MobileMainHomeNavigationProp
) {
  // Split the path by "/"
  if (!url_path || url_path.trim() === "" || url_path.trim() === "/") {
    changeUrl("");
    navigation.navigate("Home");
    console.log("going Home as path is none");
    return;
  }
  const pathParts = url_path.split("/");
  console.log("should be navigating: ", pathParts);

  switch (pathParts[1].toLowerCase()) {
    case "posts":
      console.log("going to posts");
      const post_id = pathParts[2];
      console.log(pathParts);
      changeUrl(`posts/${post_id}`);
      if (post_id) {
        console.log("posts: ", post_id);
        navigation.navigate("InnerCell", {
          post_id,
        });
      }

      break;
    case "users":
      const user_id = pathParts[2];
      changeUrl(`users/${user_id}`);
      navigation.navigate("Users", {
        passed_user_id: user_id,
      });

      break;
    // case "chat":
    //   changeUrl("chat");
    //   navigation.navigate("Chat");
    //   break;
    case "login":
      changeUrl("login");
      navigation.navigate("Login");
      break;
    case "research":
      if (!pathParts[2]) {
        changeUrl("research");
        navigation.navigate("Research");
      } else if ((pathParts[2] = "pc-agent")) {
        changeUrl(`research/pc-agent`);
        navigation.navigate("PCAgentPage");
      } else {
        changeUrl("research");
        navigation.navigate("Research");
      }

      break;
    case "ai":
      // for tabbar to skip
      break;
    default:
      changeUrl("");
      navigation.navigate("Home");
      break;
  }
}

export { MobileMainNavigator, DesktopMainNavigator };
