import React, { useState, useEffect, useContext } from "react";
import { NavigationContainer } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import utils from "./utils"; // Import your utils module
import { isMobile } from "react-device-detect";
import { NavigationContainerRef } from "@react-navigation/native";
import { MobileBottomTabParams } from "../Navigation/navigationTypes";
import { View, Image, TouchableOpacity, StyleSheet, Text } from "react-native";
import {
  useNavigation,
  useFocusEffect,
  useNavigationState,
} from "@react-navigation/native"; // Import useNavigation
import { DesktopMainStackNavigationProp } from "../Navigation/navigationTypes";
import { AuthContext } from "../Auth/auth-context";
import * as Icon from "react-native-feather";
import { changeUrl } from "../App";

const TopBar: React.FC = React.memo(() => {
  const { userData, isLoggedIn, isLoading } = useContext(AuthContext); // Get the user image and isUserLoggedIn state from the UserContext

  const navigation = useNavigation<DesktopMainStackNavigationProp>();

  const routeName = useNavigationState((state) => {
    return state?.routes[state?.index]?.name;
  });

  const handleLoginClick = () => {
    console.log("Login clicked");
    changeUrl(`login`);

    navigation.navigate("Login");
  };

  const handleProfileClick = () => {
    console.log("Profile clicked");
    changeUrl(`users/${userData.user_id}`);
    navigation.navigate("User", { passed_user_id: userData.user_id });
    // we going to our own profile
  };
  const handleLogoClick = () => {
    console.log("Logo clicked");
    changeUrl(``);
    navigation.navigate("Home");
  };
  const handleMenuPress = () => {
    // handleLogoClick();
    console.log("Menu clicked");
  };

  return (
    <View style={styles.container}>
      <View style={styles.logo}>
        <TouchableOpacity onPress={handleMenuPress}>
          <Icon.Menu />
        </TouchableOpacity>
        <TouchableOpacity onPress={handleLogoClick}>
          <Text
            style={{
              fontFamily: "Comfortaa",
              fontSize: 24,
              fontWeight: "600",
              marginLeft: 15,
              color: "black",
            }}
          >
            Gate
          </Text>
        </TouchableOpacity>
      </View>
      {!isLoading &&
        routeName != "Login" &&
        (isLoggedIn ? (
          <TouchableOpacity onPress={handleProfileClick}>
            <Image
              source={{
                uri: userData.avatarUrl,
              }}
              style={styles.profileImage}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={styles.loginButton}
            onPress={handleLoginClick}
          >
            <Icon.LogIn color={"white"} />
            <Text style={styles.loginButtonText}>Log In</Text>
          </TouchableOpacity>
        ))}
    </View>
  );
});
const styles = StyleSheet.create({
  container: {
    // position: "absolute", // Add this line

    // top: 0, // Add this line
    // left: 0, // Add this line
    // right: 0, // Add this line
    height: 60, // Set the height of the top bar
    backgroundColor: "#f8f8f8", // Set the background color of the top bar
    width: "100%", // Make the container span the entire width
    flexDirection: "row", // Set main-axis to horizontal (left to right)
    justifyContent: "space-between", // Distribute children evenly along the main-axis
    alignItems: "center", // Align children along the cross-axis (vertically)
    paddingHorizontal: 25, // Add horizontal padding
    paddingRight: 30,
    zIndex: 1, // Ensure the TopBar stays on top
  },
  logo: { flexDirection: "row" },
  profileImage: {
    borderRadius: 20, // Make the profile image circular
    width: 35, // Set the width of the profile image
    height: 35, // Set the height of the profile image
    borderWidth: 1.5, // Add this line to set the width of the border
    borderColor: "black", // Add this line to set the color of the border
  },
  logoImage: {
    borderRadius: 35, // Make the profile image circular
    width: 35,
    height: 35, // Set the height of the profile image
  },
  loginButton: {
    flexDirection: "row",
    backgroundColor: "black", // Set the background color of the button
    padding: 6,
    borderRadius: 4, // Round the corners
    marginRight: 20,
  },
  loginButtonText: {
    color: "white", // Set the text color
    fontSize: 16, // Set the font size
    fontWeight: "bold", // Make the text bold
    fontFamily: "Comfortaa",
    marginTop: 4,
    marginHorizontal: 10,
  },
});

export default TopBar;
