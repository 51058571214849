import React, { useEffect, useContext, useState, useRef } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
//import Markdown from "@valasolutions/react-native-markdown";
//import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import { Dimensions } from "react-native";

import { AuthContext } from "../Auth/auth-context";
import {
  DesktopMainStackNavigationProp,
  MobileMainHomeNavigationProp,
} from "../Navigation/navigationTypes";
import { createClient, ICreateClientOpts } from "matrix-js-sdk";
import Markdown from "react-native-markdown-display";
import * as Icon from "react-native-feather";
import { changeUrl } from "../App";

import utils, {
  InteractionManager,
  eventSourceChat,
  sendMessage,
} from "../Misc/utils"; // Import your utility module
import {
  View,
  Text,
  TextInput,
  Button,
  FlatList,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  ScrollView,
  Image,
} from "react-native";
import { useNavigation, useFocusEffect } from "@react-navigation/native"; // Import useNavigation
import { chat_send } from "../assets/icons"; // Import the SVG components
import { CellType } from "../Posts/Cell";
import MiniCell from "../Posts/MiniCell";
import { RouteProp } from "@react-navigation/native";

const interactionManager = InteractionManager.getInstance();
export type ChatbotMessage = { role: string; content: string };

const AIChat: React.FC = () => {
  const isMobileOS =
    Platform.OS === "ios" || Platform.OS === "android" || isMobile;
  // const clientOpts: ICreateClientOpts = {
  //   baseUrl: "https://tchncs.de",
  //   idBaseUrl: "https://tchncs.de",
  // };
  // const client = createClient(clientOpts);

  const { userData, isLoggedIn, isLoading } = useContext(AuthContext);
  console.log(isLoggedIn);
  const inputRef = useRef<TextInput>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [isAtBottom, setIsAtBottom] = useState(true); // Add this state

  const flatListRef = useRef<FlatList>(null); // Add this ref

  const mainNavigation = useNavigation<MobileMainHomeNavigationProp>();

  const handleLoginClick = () => {
    changeUrl(`login`);
    mainNavigation.navigate("Login");
  };
  const [sendDisabled, setSendDisabled] = useState(true); // Add this line
  const [loginChecked, setLoginChecked] = useState(false); // Add this line

  const insets = useSafeAreaInsets();
  const [chatId, setChatId] = useState(null);

  const [messages, setMessages] = useState<ChatbotMessage[]>([]);
  const [inputMessage, setInputMessage] = useState<string>("");

  useFocusEffect(
    React.useCallback(() => {
      if (!isLoading && !isLoggedIn) {
        handleLoginClick();
      }
      setLoginChecked(true);
    }, [isLoggedIn, isLoading])
  );
  const newChat = async () => {
    // client
    //   .registerRequest({
    //     username: "johnCena243454",
    //     password: "HackerMan87ss@",
    //   })
    //   .then((response) => {
    //     console.log("Account created:", response);
    //   })
    //   .catch((error) => {
    //     console.error("Error creating account:", error);
    //   });
    // Clear the local state
    setMessages([]);
    const response = await utils.get("/ai/new");
    if (response.ok) {
      console.log("Started new chat");
      const data = await response.json();
      setChatId(data.chat_id); // Store the chat_id in state
      //setSendDisabled(false);
    } else {
      console.log((await response.json()).message);
    }
  };
  const scrollToEnd = (delay = 50, animated = false) => {
    setTimeout(() => {
      if (flatListRef.current) {
        console.log("scrolling to end instantly");
        flatListRef.current.scrollToEnd({ animated: animated });
      }
    }, delay);
  };
  const loadLastChat = async () => {
    // Clear the local state
    setMessages([]);
    const response = await utils.get("/ai/last");
    if (response.ok) {
      console.log("Loaded chat");
      const data = await response.json();
      setChatId(data.chat_id); // Store the chat_id in state
      console.log(data.chat_id);
      if (data.chat_history) {
        setMessages(data.chat_history);
        scrollToEnd(100);
      } else {
        setMessages([]);
      }
      // scroll to end
      // only enable the thing if last chat has text unfinished
      //setSendDisabled(false);
    } else {
      console.log((await response.json()).message);
    }
  };
  const sendStream = async (userPrompt: string, chatID: string) => {
    let newMessages = [
      ...messages,
      {
        role: "user",
        content: userPrompt,
      },
    ];
    const messagesToSend = newMessages.slice(-15); // Create a new copy of the last 15 messages
    console.log(messagesToSend);
    // temp message
    newMessages.push({ role: "assistant", content: "" });
    setMessages(newMessages);
    // console.log("sending messages: ", messagesToSend);

    const sse = await eventSourceChat(messagesToSend, chatID);

    console.log("inited sse:");
    console.log(sse);

    sse.addEventListener("close", () => {
      console.log("CLOSE");
      return;
    });

    // listen to incoming messages
    sse.addEventListener("message", ({ data }) => {
      console.log(data);
      if (data) {
        const json = JSON.parse(data);
        if (json.close) {
          console.log("Connection closed: ", json.close);
          sse.removeAllEventListeners();
          sse.close();
          return;
        } else if (json.content) {
          const chunk = json.content;
          console.log(chunk);
          setMessages((oldMessages) => {
            let newMessages = [...oldMessages];
            newMessages[newMessages.length - 1].content += chunk;
            return newMessages;
          });
          if (isAtBottom) {
            scrollToEnd(0);
          }
        }
      }
    });

    // // Event listener for when an error occurs
    // sse.addEventListener("error", (e) => {
    //   console.error("A CHATSTREAM error occurred: ", e);
    //   // error

    //   // pop the user and ai message
    //   newMessages.pop();
    //   newMessages.pop();
    //   setMessages([...newMessages]);

    //   // rollback input
    //   setInputMessage(userPrompt);
    // });
  };

  const handleMessageSend = async () => {
    if (inputMessage.trim() === "" || sendDisabled || !chatId) return; // Don't send empty messages or if already sending or if chatid null
    const message = inputMessage;
    console.log(inputMessage, chatId);
    setInputMessage("");
    setSendDisabled(true); // Set isSending to true when sending a message

    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
    if (textareaRef && textareaRef.current) {
      textareaRef.current.focus();
      //desktop text area
    }

    await sendStream(message, chatId);
    // focus again on the message

    if (isAtBottom) {
      scrollToEnd(50);
    }
    if (isAtBottom) {
      scrollToEnd(200, true);
    }
  };

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        if (isAtBottom) {
          scrollToEnd(10);
        }
      }
    );

    return () => {
      keyboardDidShowListener.remove();
    };
  }, []);
  function onChangeText(text: string) {
    setInputMessage(text);
    if (text.length == 0) {
      setSendDisabled(true);
    } else if (sendDisabled) {
      setSendDisabled(false);
    }
  }

  useEffect(() => {
    loadLastChat();
    mainNavigation.setOptions({
      headerRight: () => (
        <TouchableOpacity onPress={newChat} style={{ paddingRight: 22 }}>
          <Icon.PlusSquare
            stroke="black"
            width={28}
            height={28}
            strokeWidth={1.5}
          />
        </TouchableOpacity>
      ),
    });
  }, []);

  let mobile = (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.mobileContainer}
      keyboardVerticalOffset={insets.top + 45} // Adjust this value as needed
    >
      <FlatList
        keyboardShouldPersistTaps="handled" // close keyboard on tap - leave on scroll
        ref={flatListRef}
        windowSize={6} // performance
        onScroll={({ nativeEvent }) => {
          const threshold = 10; // Adjust this value as needed
          setIsAtBottom(
            nativeEvent.contentOffset.y +
              nativeEvent.layoutMeasurement.height +
              threshold >=
              nativeEvent.contentSize.height
          );
        }}
        scrollEventThrottle={100}
        data={messages}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => (
          <View
            onLayout={() => {
              // Scroll to end if this is a new message and user is at bottom
              //console.log(isAtBottom);
              if (isAtBottom) {
                scrollToEnd(10);
              }
            }}
            style={item.role === "user" ? styles.userMessage : styles.aiMessage}
          >
            <View style={styles.messageHeader}>
              {item.role === "user" ? (
                // <Image source={{ uri: avatarUrl }} style={styles.avatar} />
                <Icon.User style={styles.avatar} />
              ) : (
                <Icon.Disc style={styles.avatar} />
              )}

              <Text style={styles.roleName}>
                {/* {item.role === "user" ? displayName : "Gate AI"} */}
                {item.role === "user" ? "You" : "Gate AI"}
              </Text>
            </View>
            <View style={styles.content}>
              <Markdown
                style={StyleSheet.create({
                  text: { fontSize: 16 },
                })}
                rules={{
                  html: () => null, // Disable HTML rendering
                }}
              >
                {item.content}
              </Markdown>

              {/* <Markdown remarkPlugins={[remarkGfm]}>{item.content}</Markdown> */}
              {/* removed markdown  style={styles.text}   */}
            </View>
          </View>
        )}
      />
      {!isAtBottom && (
        <TouchableOpacity
          style={{
            flex: 1,
            justifyContent: "center",
            flexDirection: "row",
            marginBottom: 30,
            backgroundColor: "transparent",
          }}
          onPress={() => {
            scrollToEnd(0, true);
          }}
        >
          {/* Replace "Scroll to bottom" with your SVG */}
          <Icon.ArrowDown />
        </TouchableOpacity>
      )}

      <View style={styles.mobileInputContainer}>
        <TextInput
          ref={inputRef}
          style={styles.input}
          placeholder="Message"
          value={inputMessage}
          onChangeText={(text) => onChangeText(text)}
          multiline // This makes the TextInput multiline
        />

        <TouchableOpacity
          onPress={handleMessageSend}
          disabled={sendDisabled}
          style={styles.sendButton}
        >
          {sendDisabled ? (
            <Icon.ArrowUpCircle
              fill="gray"
              stroke="#f2f2f2"
              strokeWidth={1.5}
              width={34}
              height={34}
            />
          ) : (
            <Icon.ArrowUpCircle
              fill="black"
              stroke="#f2f2f2"
              strokeWidth={1.5}
              width={34}
              height={34}
            />
          )}
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  );
  let desktop = (
    <>
      <TouchableOpacity
        onPress={newChat}
        style={{
          position: "absolute",
          left: 10, // Dimensions.get("window").width * 0.3 - 40,
          bottom: 10,
          padding: 25,
          zIndex: 999,
        }}
      >
        <Icon.PlusSquare
          stroke="black"
          width={28}
          height={28}
          strokeWidth={1.5}
        />
      </TouchableOpacity>
      <View style={styles.desktopContainer}>
        <FlatList
          keyboardShouldPersistTaps="handled" // close keyboard on tap - leave on scroll
          ref={flatListRef}
          style={styles.desktopFlatlist}
          windowSize={6} // performance
          onScroll={({ nativeEvent }) => {
            const threshold = 10; // Adjust this value as needed
            setIsAtBottom(
              nativeEvent.contentOffset.y +
                nativeEvent.layoutMeasurement.height +
                threshold >=
                nativeEvent.contentSize.height
            );
          }}
          scrollEventThrottle={100}
          data={messages}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <View
              onLayout={() => {
                // Scroll to end if this is a new message and user is at bottom
                //console.log(isAtBottom);
                if (isAtBottom) {
                  scrollToEnd(10);
                }
              }}
              style={
                item.role === "user" ? styles.userMessage : styles.aiMessage
              }
            >
              <View style={styles.messageHeader}>
                {item.role === "user" ? (
                  // <Image source={{ uri: avatarUrl }} style={styles.avatar} />
                  <Icon.User style={styles.avatar} />
                ) : (
                  <Icon.Disc style={styles.avatar} />
                )}

                <Text style={styles.roleName}>
                  {/* {item.role === "user" ? userData.displayName : "Gate AI"} */}
                  {item.role === "user" ? "You" : "Gate AI"}
                </Text>
              </View>
              <View style={styles.content}>
                <Markdown
                  style={StyleSheet.create({
                    text: { fontSize: 16 },
                  })}
                >
                  {item.content}
                </Markdown>

                {/* <Markdown remarkPlugins={[remarkGfm]}>{item.content}</Markdown> */}
                {/* removed markdown  style={styles.text}   */}
              </View>
            </View>
          )}
        />
        {!isAtBottom && (
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: "center",
              flexDirection: "row",
              marginBottom: 30,
              backgroundColor: "transparent",
              minWidth: 750,
              paddingHorizontal: (Dimensions.get("window").width - 750) / 2,
            }}
            onPress={() => {
              scrollToEnd(0, true);
            }}
          >
            {/* Replace "Scroll to bottom" with your SVG */}
            <Icon.ArrowDown />
          </TouchableOpacity>
        )}

        <View style={styles.desktopInputContainer}>
          <textarea
            ref={textareaRef}
            style={{
              fontSize: 15,
              flex: 1,
              outlineStyle: "none",
              marginRight: 0,
              borderColor: "#dddddd",
              backgroundColor: "#dddddd",
              borderWidth: 1,
              borderRadius: 16,
              padding: 8,
              minHeight: 36,
              maxHeight: 180,
              overflow: "auto", // add this
              height: "auto",
              resize: "none", // add this to disable manual resizing
            }}
            placeholder="Message"
            value={inputMessage}
            onChange={(e) => onChangeText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleMessageSend();
              }
            }}
          />

          <TouchableOpacity
            onPress={handleMessageSend}
            disabled={sendDisabled}
            style={styles.sendButton}
          >
            {sendDisabled ? (
              <Icon.ArrowUpCircle
                fill="gray"
                stroke="#f2f2f2"
                strokeWidth={1.5}
                width={34}
                height={34}
              />
            ) : (
              <Icon.ArrowUpCircle
                fill="black"
                stroke="#f2f2f2"
                strokeWidth={1.5}
                width={34}
                height={34}
              />
            )}
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
  return isMobileOS ? mobile : desktop;
};
const styles = StyleSheet.create({
  sendButton: {
    width: 27,
    padding: 5,
  },
  mobileContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
  desktopContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
  desktopFlatlist: {
    // width: "40%", // Make the list take up the full width of its container
    // minWidth: 500,
    // alignSelf: "center",
    // paddingHorizontal: "30%",
    // marginHorizontal: "auto", // Add this line
    minWidth: 750,
    paddingHorizontal: (Dimensions.get("window").width - 750) / 2,
  },
  content: {
    marginHorizontal: 30,
  },
  userMessage: {
    alignSelf: "flex-start",
    backgroundColor: "transparent", // change this to 'white' for a white background
    padding: 8,
    marginVertical: 8,
    borderRadius: 8,
  },
  aiMessage: {
    alignSelf: "flex-start",
    backgroundColor: "transparent", // change this to 'white' for a white background
    padding: 8,
    marginVertical: 4,
    borderRadius: 8,
  },

  messageHeader: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4,
  },
  avatar: {
    width: 18,
    height: 18,
    borderRadius: 15,
    marginRight: 6,
    marginLeft: 4,
    marginBottom: 1,
  },
  roleName: {
    fontSize: 13,
    fontWeight: "bold",
    fontFamily: "ComfortaaMed",
  },

  desktopInputContainer: {
    minWidth: 750,
    paddingHorizontal: (Dimensions.get("window").width - 750) / 2,
    flexDirection: "row",
    alignItems: "center",
    //paddingHorizontal: 16,
    // paddingVertical: 12,
    //paddingTop: 30,
    marginHorizontal: 10,
    marginBottom: 20,
  },
  mobileInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    // paddingVertical: 12,
    //paddingTop: 30,
    marginBottom: 20,
  },
  input: {
    fontSize: 15,
    flex: 1,
    // outlineStyle: "none",

    marginRight: 8,
    borderColor: "#dddddd",
    backgroundColor: "#dddddd",
    borderWidth: 1,
    borderRadius: 16,
    padding: 8,
    height: 40, // Set your desired max height here
  },
});

export default AIChat;
