import * as sdk from "matrix-js-sdk";
import axios from "axios";

const HOMESERVER_URL = "https://52.1.107.117.sslip.io";

export class MatrixManager {
  private static instance: MatrixManager;
  private static accessToken: string | null = null;

  private constructor() {}

  public static getInstance(accessToken?: string): MatrixManager {
    if (!MatrixManager.instance) {
      MatrixManager.instance = new MatrixManager();
      if (accessToken) MatrixManager.accessToken = accessToken;
    }
    return MatrixManager.instance;
  }
  public setAccessToken(accessToken?: string) {
    if (accessToken) MatrixManager.accessToken = accessToken;
  }
  public async connectClient() {
    if (!MatrixManager.accessToken) {
      throw new Error("Access token is not set");
    }
    try {
      const response = await axios.get(
        `${HOMESERVER_URL}/_matrix/client/v3/profile`,
        {
          headers: {
            Authorization: `Bearer ${MatrixManager.accessToken}`,
          },
        }
      );
      // Initialize client with response data
      console.log(response);
    } catch (error) {
      console.error(`Error connecting to Matrix server:`, error);
    }
  }

  public async getRooms(): Promise<{ roomId: string; title: string }[]> {
    if (!MatrixManager.accessToken) {
      throw new Error("Access token is not set");
    }
    try {
      const response = await axios.get(
        `${HOMESERVER_URL}/_matrix/client/v3/joined_rooms`,
        {
          headers: {
            Authorization: `Bearer ${MatrixManager.accessToken}`,
          },
        }
      );
      const roomIds = response.data.joined_rooms;
      const rooms: { roomId: string; title: string }[] = await Promise.all(
        roomIds.map(async (roomId: string) => {
          const stateResponse = await axios.get(
            `${HOMESERVER_URL}/_matrix/client/v3/rooms/${roomId}/state/m.room.name`,
            {
              headers: {
                Authorization: `Bearer ${MatrixManager.accessToken}`,
              },
            }
          );
          console.log(stateResponse.data);
          const title = stateResponse.data.name;
          return { roomId, title: title || "" };
        })
      );
      return rooms;
    } catch (error) {
      console.error(`Error fetching rooms:`, error);
      return [];
    }
  }

  public async pollEvents(roomId: string) {
    if (!MatrixManager.accessToken) {
      throw new Error("Access token is not set");
    }
    try {
      const response = await axios.get(
        `${HOMESERVER_URL}/_matrix/client/v3/events?room_id=${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${MatrixManager.accessToken}`,
          },
          timeout: 5000,
        }
      );

      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
  public async fetchMessages(roomId: string, limit: number = 150) {
    if (!MatrixManager.accessToken) {
      throw new Error("Access token is not set");
    }
    const response = await axios.get(
      `${HOMESERVER_URL}/_matrix/client/v3/rooms/${roomId}/messages?limit=${limit}&dir=b&filter={"types":["m.room.message","m.room.member"]}`,
      {
        headers: {
          Authorization: `Bearer ${MatrixManager.accessToken}`,
        },
      }
    );
    console.log(response);
    const messages = response.data.chunk.reverse();
    return messages;
  }
  public async getEventInRoom(eventId: string, roomId: string) {
    if (!MatrixManager.accessToken) {
      throw new Error("Access token is not set");
    }
    try {
      const eventResponse = await axios.get(
        `${HOMESERVER_URL}/_matrix/client/v3/rooms/${roomId}/event/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${MatrixManager.accessToken}`,
          },
        }
      );
      return eventResponse.data; // Full event object
    } catch (error) {
      console.error(`Error getting event from room ${roomId}:`, error);
    }
  }
  public async sendMessage(roomId: string, message: string) {
    if (!MatrixManager.accessToken) {
      throw new Error("Access token is not set");
    }
    try {
      const response = await axios.post(
        `${HOMESERVER_URL}/_matrix/client/v3/rooms/${roomId}/send/m.room.message`,
        {
          msgtype: "m.room.message",
          body: message,
        },
        {
          headers: {
            Authorization: `Bearer ${MatrixManager.accessToken}`,
          },
        }
      );
      return response;
      // if (response.status == 200) {
      //   const event = await this.getEventInRoom(response.data.event_id, roomId);
      //   return event;
      // }
    } catch (error) {
      console.error(`Error sending message to room ${roomId}:`, error);
    }
  }
}

// export async function fetchMessages(
//   roomId: string,
//   client: sdk.MatrixClient,
//   limit: number = 50
// ): Promise<sdk.MatrixEvent[]> {
//   try {
//     const room = client.getRoom(roomId);
//     if (!room) return [];
//     const response = await client.scrollback(room, limit);
//     return response.get;
//   } catch (error) {
//     console.error(`Error fetching messages for room ${roomId}:`, error);
//     return [];
//   }
// }
