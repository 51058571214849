// App.tsx
import React, { useState, useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import {
  MobileMainNavigator,
  DesktopMainNavigator,
} from "./Navigation/Navigator";
import { AuthContext } from "./Auth/auth-context";
import { View, ScrollView, SafeAreaView, Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import utils from "./Misc/utils"; // Import your utils module
import { isMobile } from "react-device-detect";
import { NavigationContainerRef } from "@react-navigation/native";
import { MobileBottomTabParams } from "./Navigation/navigationTypes";
import TopBar from "./Misc/DesktopTopbar";
import HomePage from "./Components/HomePage";
import { CellProps } from "./Posts/Cell";
import { Analytics } from "@vercel/analytics/react";
import * as Font from "expo-font";
import { UserProps } from "./Auth/auth-context";
declare global {
  interface Window {
    __CELL_DATA__: CellProps | undefined;
    __USER_DATA__: UserProps | undefined;
  }
}
export const changeUrl = (url: string) => {
  if (Platform.OS === "web") {
    // Change the URL without causing a navigation event
    console.log("changing url");
    console.log(url);
    window.history.pushState(null, "", `/${url}`);
  }
};
const App: React.FC = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = React.useState<UserProps>({
    user_id: "",
    username: "",
    displayName: "",
    avatarUrl: "",
    matrixAccessToken: "",

    email: undefined,
  });

  const isMobileOS =
    Platform.OS === "ios" || Platform.OS === "android" || isMobile;
  console.log("ismobile os", isMobile);

  const logOut = () => {
    setUserData({
      user_id: "",
      username: "",
      displayName: "",
      avatarUrl: "",
      matrixAccessToken: "",
      email: undefined,
    });
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const loadFonts = async () => {
      await Font.loadAsync({
        Comfortaa: require("./assets/fonts/Comfortaa.ttf"),
        ComfortaaMed: require("./assets/fonts/Comfortaa-Medium.ttf"),
        Futura: require("./assets/fonts/Futura.ttf"),
      });
      setFontsLoaded(true);
    };

    const checkToken = async () => {
      try {
        const token = await AsyncStorage.getItem("token");
        console.log(token);

        if (token) {
          // Check if the token is valid

          // get request always includes token from storage
          const response = await utils.get("/users/auth");
          if (response.status === 200) {
            // update token
            const data = await response.json();
            await AsyncStorage.setItem("token", data.token);
            setIsLoggedIn(true);
            setUserData(data.userData);
            setIsLoading(false);
          } else {
            console.error(await response.json());
            setIsLoggedIn(false);
            setIsLoading(false);
          }
        } else {
          //await tempRegister();
          setIsLoggedIn(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error checking token:", error);
        setIsLoggedIn(false);
        setIsLoading(false);
      }
    };
    loadFonts();
    checkToken();
  }, []);

  if (!fontsLoaded || isLoading) {
    return <View />;
    // loading screen before everything
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          logOut,
          userData,
          setUserData,
          isLoggedIn,
          setIsLoggedIn,
          isLoading,
          setIsLoading,
        }}
      >
        <NavigationContainer>
          {isMobileOS ? <MobileMainNavigator /> : <DesktopMainNavigator />}
        </NavigationContainer>
      </AuthContext.Provider>
      <Analytics />
    </>
  );
};

export default App;
