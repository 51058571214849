import React, { useEffect, useContext, useState, useRef } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
//import Markdown from "@valasolutions/react-native-markdown";
//import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { Helmet } from "react-helmet-async";
import { isMobile } from "react-device-detect";
import { MatrixManager } from "../Misc/Matrix";
import { AuthContext, UserProps } from "../Auth/auth-context";
import {
  DesktopMainStackNavigationProp,
  MobileMainHomeNavigationProp,
} from "../Navigation/navigationTypes";
import {
  createClient,
  ICreateClientOpts,
  MatrixCall,
  MatrixEventEvent,
} from "matrix-js-sdk";
import Markdown from "react-native-markdown-display";
import * as Icon from "react-native-feather";
import { Dimensions } from "react-native";
import utils, {
  InteractionManager,
  eventSourceChat,
  sendMessage,
} from "../Misc/utils"; // Import your utility module
import { fetchUserInfo } from "../User/UserPage";
import {
  View,
  Text,
  TextInput,
  Button,
  FlatList,
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  ScrollView,
  Image,
} from "react-native";
import { useNavigation, useFocusEffect } from "@react-navigation/native"; // Import useNavigation
import { ai, chat_send } from "../assets/icons"; // Import the SVG components
import { CellType } from "../Posts/Cell";
import MiniCell from "../Posts/MiniCell";
import { RouteProp } from "@react-navigation/native";
import { useLocalStorage } from "react-use";
import { ChatbotMessage } from "./AIChat";
const interactionManager = InteractionManager.getInstance();
const matrixManager = MatrixManager.getInstance();

type MatrixMsgEvent = {
  age?: number;
  content?: {
    body?: string;
    msgtype?: string;
    displayname?: string;
    membership?: string;
    m?: {
      mentions?: any[];
    };
  };
  event_id?: string;
  state_key?: string;

  origin_server_ts?: number;
  room_id?: string;
  sender?: string;
  type?: string;
  unsigned?: {
    age?: number;
    membership: string;
  };
  user_id?: string;
};
export interface Message {
  content?: string;
  joined?: boolean;
  user_id: string;
}

export interface ChatParams {
  chat_id: number;
}

export interface RoomParams {
  title: string;
  roomId: string;
  isActive: boolean;
  data: MatrixMsgEvent[];
  onPress(roomId: string): any;
}
const Channel: React.FC<RoomParams> = React.memo(
  ({ title, roomId, isActive, onPress, data }) => (
    <TouchableOpacity
      style={[styles.channel, isActive && styles.activeChannel]}
      onPress={() => onPress(roomId)}
    >
      <Icon.Hash
        color={isActive ? "black" : "grey"}
        strokeWidth={isActive ? 1.6 : 1.2}
        style={styles.iconContainer}
      />
      <Text
        style={[styles.channelTitle, isActive && styles.activeChannelTitle]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  )
);
export interface AIParams {
  title: string;
  isActive: boolean;
  onPress(): any;
}
const AIChannel: React.FC<AIParams> = React.memo(
  ({ title, isActive, onPress }) => (
    <TouchableOpacity
      style={[styles.channel, isActive && styles.activeChannel]}
      onPress={() => onPress()}
    >
      <Icon.Hash
        color={isActive ? "black" : "grey"}
        strokeWidth={isActive ? 1.6 : 1.2}
        style={styles.iconContainer}
      />
      <Text
        style={[styles.channelTitle, isActive && styles.activeChannelTitle]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  )
);

const ChannelsBar: React.FC = () => {
  const { channels, selectedChannelID, setSelectedChannelID, aiChat } =
    useContext(ChatContext);
  const { aiSelected, setAiSelected, aiMessages, setAiMessages, chatId } =
    aiChat;
  const handleAIPress = () => {
    setSelectedChannelID(undefined);
    setAiSelected(true);
  };
  const handleChannelPress = (roomId: string) => {
    const selected = channels.find((channel) => channel.roomId === roomId);
    if (selected) {
      setSelectedChannelID(roomId);
      setAiSelected(false);
      // also auto focus on input box
    }
  };
  return (
    <View style={styles.channelsBar}>
      {/* <Text style={styles.channelsHeader}>Channels</Text> */}
      <FlatList
        data={channels}
        renderItem={({ item }) => (
          <Channel
            {...item}
            isActive={selectedChannelID === item.roomId}
            onPress={handleChannelPress}
          />
        )}
        keyExtractor={(item) => item.roomId.toString()}
      />
      <AIChannel
        title={"assistant"}
        isActive={aiSelected}
        onPress={handleAIPress}
      />
    </View>
  );
};

export interface ChatContextProps {
  channels: RoomParams[];
  setChannels: (channels: RoomParams[]) => void;
  selectedChannelID?: string;
  setSelectedChannelID: (selectedChannelID?: string) => void;
  aiChat: AIChat;
}

type AIChat = {
  aiSelected: boolean;
  setAiSelected: (aiSelected: boolean) => void;
  aiMessages: ChatbotMessage[];
  setAiMessages: (aiMessages: ChatbotMessage[]) => void;
  chatId?: string;
  setChatId: (chatId: string) => void;
};
export const ChatContext = React.createContext<ChatContextProps>({
  channels: [],
  selectedChannelID: undefined,
  setChannels: () => {},
  setSelectedChannelID: () => {},
  aiChat: {
    aiSelected: false,
    setAiSelected: () => {},
    aiMessages: [],
    setAiMessages: () => {},
    chatId: undefined,
    setChatId: () => {},
  },
});
export const UserDatabaseContext = React.createContext<
  Record<string, UserProps>
>({});

const DesktopChat: React.FC = () => {
  const [selectedChannelID, setSelectedChannelID] = useState<string>();

  const [channels, setChannels] = useState<RoomParams[]>([]);

  const [aiSelected, setAiSelected] = useState<boolean>(false);
  const [aiMessages, setAiMessages] = useState<ChatbotMessage[]>([]);
  const [chatId, setChatId] = useState<string | undefined>(undefined);

  // {
  //   title: "general-01",
  //   channel_id: "1",
  //   isActive: false,
  //   data: [],
  //   onPress: () => {},
  // },
  // {
  //   title: "general-02",
  //   channel_id: "2",
  //   isActive: false,
  //   data: [],
  //   onPress: () => {},
  // },

  // const clientOpts: ICreateClientOpts = {
  //   baseUrl: "https://tchncs.de",
  //   idBaseUrl: "https://tchncs.de",
  // };
  // const client = createClient(clientOpts);

  const { userData, isLoggedIn, isLoading } = useContext(AuthContext);
  // console.log(userData.matrixAccessToken);

  // console.log(isLoggedIn);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [isAtBottom, setIsAtBottom] = useState(true); // Add this state
  matrixManager.setAccessToken(userData.matrixAccessToken);
  const flatListRef = useRef<FlatList>(null); // Add this ref

  const [sendDisabled, setSendDisabled] = useState(true); // Add this line
  const [loginChecked, setLoginChecked] = useState(false); // Add this line

  const insets = useSafeAreaInsets();

  const [rawMessages, setRawMessages] = useState<Message[]>([]);

  const [inputMessage, setInputMessage] = useState<string>("");
  const userDatabase = useContext(UserDatabaseContext);
  const newAIChat = async () => {
    // client
    //   .registerRequest({
    //     username: "johnCena243454",
    //     password: "HackerMan87ss@",
    //   })
    //   .then((response) => {
    //     console.log("Account created:", response);
    //   })
    //   .catch((error) => {
    //     console.error("Error creating account:", error);
    //   });
    // Clear the local state
    setAiMessages([]);
    const response = await utils.get("/ai/new");
    if (response.ok) {
      console.log("Started new chat");
      const data = await response.json();
      setChatId(data.chat_id); // Store the chat_id in state
      //setSendDisabled(false);
    } else {
      console.log((await response.json()).message);
    }
  };
  const loadLastAIChat = async () => {
    // Clear the local state
    setAiMessages([]);
    const response = await utils.get("/ai/last");
    if (response.ok) {
      console.log("Loaded chat");
      const data = await response.json();
      setChatId(data.chat_id); // Store the chat_id in state
      console.log(data.chat_id);
      if (data.chat_history) {
        setAiMessages(data.chat_history);
        // scrollToEnd(100);
      } else {
        setAiMessages([]);
      }
      // scroll to end
      // only enable the thing if last chat has text unfinished
      //setSendDisabled(false);
    } else {
      console.log((await response.json()).message);
    }
  };
  const prepareMessages = async () => {
    console.log("selected channel:", selectedChannelID);
    let selected = selectedChannelID;
    const preparedMessages: Message[] = [];
    let currentUserId: string | null = null;
    let currentMessage: string = "";
    const dbTasks: Promise<UserProps>[] = [];
    if (aiSelected) {
      for (const message of aiMessages) {
        // const content = message.content;
        const user_id = message.role;
        if (user_id) {
          dbTasks.push(getUserFromDB(user_id));
        }
      }
    } else if (selected) {
      for (const channel of channels) {
        if (channel.roomId !== selected) continue;

        for (const message of channel.data) {
          if (!message.user_id || !message.sender) continue;

          // const content = message.content?.body;
          const id = message.user_id ? message.user_id : message.sender;
          const user_id = getSubstringBetween(id);

          if (user_id) {
            dbTasks.push(getUserFromDB(user_id));
          }
        }
      }
    }
    await Promise.all(dbTasks);
    // first load in all images and display names and everything
    // then actuall again filter and combine

    console.log("preparing");
    if (aiSelected) {
      for (const message of aiMessages) {
        const content = message.content;
        const user_id = message.role;
        if (content && user_id) {
          preparedMessages.push({ content, user_id });
        }
      }
    } else if (selected) {
      for (const channel of channels) {
        if (channel.roomId !== selected) continue;

        for (const message of channel.data) {
          if (!message.user_id || !message.sender) continue;

          const content = message.content?.body;
          const id = message.user_id ? message.user_id : message.sender;
          const user_id = getSubstringBetween(id);
          if (content && user_id) {
            if (user_id !== currentUserId) {
              if (currentUserId !== null) {
                preparedMessages.push({
                  content: currentMessage,
                  user_id: currentUserId,
                });
                currentMessage = "";
              }
              currentUserId = user_id;
            }

            currentMessage += content + "\n";
          } else if (
            user_id &&
            message.type &&
            message.type == "m.room.member"
          ) {
            if (currentUserId !== null) {
              preparedMessages.push({
                content: currentMessage,
                user_id: currentUserId,
              });
              currentMessage = "";
              currentUserId = null;
            }
            preparedMessages.push({
              user_id: user_id,
              joined: true,
            });
          }
        }
      }
    }
    if (currentMessage.trim() !== "" && currentUserId) {
      preparedMessages.push({
        content: currentMessage,
        user_id: currentUserId,
      });
    }

    console.log(preparedMessages);
    if (selectedChannelID == selected) {
      // still same channel
      setRawMessages(preparedMessages);
    }
    console.log(rawMessages);
  };
  const getUserFromDB = async (user_id: string): Promise<UserProps> => {
    if (!userDatabase[user_id]) {
      if (user_id == "user") {
        userDatabase[user_id] = userData;
        return userData;
      } else {
        const userData = await fetchUserInfo(user_id);
        userDatabase[user_id] = userData;
        console.log(userDatabase[user_id]);
        return userData;
      }
    }

    return userDatabase[user_id];
  };

  const scrollToEnd = (delay = 50, animated = false) => {
    setTimeout(() => {
      if (flatListRef.current) {
        console.log("scrolling to end instantly");
        flatListRef.current.scrollToEnd({ animated: animated });
      }
    }, delay);
  };

  const handleMessageSend = async () => {
    if (
      inputMessage.trim() === "" ||
      sendDisabled ||
      (!selectedChannelID && !aiSelected)
    ) {
      return; // Don't send empty messages or if already sending
    }
    const message = inputMessage;
    console.log(inputMessage);

    setInputMessage("");
    setSendDisabled(true); // Set isSending to true when sending a message

    if (textareaRef && textareaRef.current) {
      textareaRef.current.focus();
      //desktop text area
    }

    if (selectedChannelID) {
      const event = await matrixManager.sendMessage(selectedChannelID, message);

      // so not only add to set messages array but also in the chat context in channels
      console.log(event);
      // if (event) {
      //   setInputMessage("");

      //   setChannels((oldChannels) => {
      //     return oldChannels.map((channel) => {
      //       if (channel.roomId === selectedChannelID) {
      //         return {
      //           ...channel,
      //           data: [event, ...channel.data],
      //         };
      //       }
      //       return channel;
      //     });
      //   });
      // } else {
      //   setInputMessage(message);
      // }
    } else if (aiSelected && chatId) {
      await sendStream(message, chatId);
    }
  };
  const sendStream = async (userPrompt: string, chatID: string) => {
    let newMessages = [
      ...aiMessages,
      {
        role: "user",
        content: userPrompt,
      },
    ];
    const messagesToSend = newMessages.slice(-15); // Create a new copy of the last 15 messages
    console.log(messagesToSend);
    // temp message
    newMessages.push({ role: "assistant", content: "" });
    setAiMessages(newMessages);
    // console.log("sending messages: ", messagesToSend);

    const sse = await eventSourceChat(messagesToSend, chatID);

    console.log("inited sse:");
    console.log(sse);

    sse.addEventListener("close", () => {
      console.log("CLOSE");
      return;
    });

    // listen to incoming messages
    sse.addEventListener("message", ({ data }) => {
      console.log(data);
      if (data) {
        const json = JSON.parse(data);
        if (json.close) {
          console.log("Connection closed: ", json.close);
          sse.removeAllEventListeners();
          sse.close();
          return;
        } else if (json.content) {
          const chunk = json.content;
          console.log(chunk);
          setAiMessages((oldMessages) => {
            let newMessages = [...oldMessages];
            newMessages[newMessages.length - 1].content += chunk;
            return newMessages;
          });
          if (isAtBottom) {
            scrollToEnd(0);
          }
        }
      }
    });

    // // Event listener for when an error occurs
    // sse.addEventListener("error", (e) => {
    //   console.error("A CHATSTREAM error occurred: ", e);
    //   // error

    //   // pop the user and ai message
    //   newMessages.pop();
    //   newMessages.pop();
    //   setMessages([...newMessages]);

    //   // rollback input
    //   setInputMessage(userPrompt);
    // });
  };

  async function pollEvents(roomId: string) {
    try {
      console.log("polling events: ", roomId);
      const event: any = await matrixManager.pollEvents(roomId);
      console.log("event", event);
      if (event && event.data && event.data.chunk && roomId) {
        const array = event.data.chunk;
        const messages = array.filter((item: any) => {
          if (item.type != "m.room.message" || item.type != "m.room.member") {
            return false;
          }
          console.log("item: ", item);

          return true;
        });
        if (messages.length == 0) {
          return;
        }
        console.log("new messages:", messages);

        setChannels((oldChannels) => {
          return oldChannels.map((channel) => {
            if (channel.roomId === roomId) {
              let updatedChannel: RoomParams = {
                ...channel,
                data: [...channel.data, ...event.data.chunk].slice(-50),
              };
              return updatedChannel;
            }
            return channel;
          });
        });
        prepareMessages();
      }
    } catch (error) {
      console.error(error);
    } finally {
      // Recursively call the pollEvents function
      pollEvents(roomId);
    }
  }

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        if (isAtBottom) {
          scrollToEnd(10);
        }
      }
    );

    return () => {
      keyboardDidShowListener.remove();
    };
  }, []);
  function onChangeText(text: string) {
    setInputMessage(text);
    if (text.length == 0) {
      setSendDisabled(true);
    } else if (sendDisabled) {
      setSendDisabled(false);
    }
  }
  function startPolls(channels: RoomParams[]) {
    // if (!selectedChannelID) return;
    console.log("startPolling");
    for (const channel of channels) {
      console.log(channel.roomId);

      // channel.roomId;
      pollEvents(channel.roomId);
    }
  }
  useEffect(() => {
    // load actual text into page when change

    if (selectedChannelID || aiSelected) {
      prepareMessages();
    }
  }, [selectedChannelID, aiSelected, aiMessages, channels]);

  useEffect(() => {
    loadLastAIChat();
  }, []);

  useEffect(() => {
    matrixManager.setAccessToken(userData.matrixAccessToken);
  }, [userData.matrixAccessToken]);
  const getSubstringBetween = (input: string) => {
    const start = input.indexOf("@user") + "@user".length;
    const end = input.indexOf(":app.gate.host");
    return input.substring(start, end);
  };
  useEffect(() => {
    try {
      const fetchData = async () => {
        console.log("REfetching data");
        const rooms = (await matrixManager.getRooms()).slice(0, 1); // only first room for now

        const channels: RoomParams[] = await Promise.all(
          rooms.map(async ({ roomId, title }) => {
            const messages = await matrixManager.fetchMessages(roomId);
            return {
              title: title,
              roomId: roomId,
              isActive: false,
              data: messages,
              onPress: () => {},
            };
          })
        );
        setChannels(channels);
        startPolls(channels);

        console.log(channels);
        if (channels.length > 0) {
          setSelectedChannelID(channels[0].roomId);
        }
      };

      fetchData();
    } catch {
      setAiSelected(true);
    }
  }, []);
  return (
    <ChatContext.Provider
      value={{
        channels,
        setChannels,
        selectedChannelID,
        setSelectedChannelID,
        aiChat: {
          aiSelected,
          setAiSelected,
          chatId,
          setChatId,
          aiMessages,
          setAiMessages,
        },
      }}
    >
      {aiSelected ? (
        <TouchableOpacity
          onPress={newAIChat}
          style={{
            position: "absolute",
            left: (Dimensions.get("window").width - 750) / 2 - 55,
            bottom: 10,
            padding: 25,
            zIndex: 999,
          }}
        >
          <Icon.PlusSquare
            stroke="black"
            width={28}
            height={28}
            strokeWidth={1.5}
          />
        </TouchableOpacity>
      ) : null}
      <View style={styles.container}>
        <View style={styles.sidebarContainer}>
          <ChannelsBar />
          {/* <AIBar /> */}
        </View>
        <View style={styles.chatContainer}>
          <FlatList
            keyboardShouldPersistTaps="handled" // close keyboard on tap - leave on scroll
            ref={flatListRef}
            style={styles.flatList}
            windowSize={6} // performance
            onScroll={({ nativeEvent }) => {
              const threshold = 10; // Adjust this value as needed
              setIsAtBottom(
                nativeEvent.contentOffset.y +
                  nativeEvent.layoutMeasurement.height +
                  threshold >=
                  nativeEvent.contentSize.height
              );
            }}
            scrollEventThrottle={0}
            data={rawMessages}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <View
                onLayout={() => {
                  // Scroll to end if this is a new message and user is at bottom
                  //console.log(isAtBottom);
                  if (isAtBottom) {
                    scrollToEnd(10);
                  }
                }}
              >
                <View style={styles.messageHeader}>
                  {item.joined ? (
                    <Icon.CornerDownRight scale={0.5} color="grey" />
                  ) : null}
                  {userDatabase[item.user_id].avatarUrl === "ai" ? (
                    <Icon.Disc style={styles.avatar} />
                  ) : (
                    <Image
                      style={[
                        styles.avatar,

                        item.joined
                          ? { opacity: 0.5, width: 18, height: 18 }
                          : {}, // Reduce opacity if joined is true
                      ]}
                      source={{ uri: userDatabase[item.user_id].avatarUrl }}
                    ></Image>
                  )}

                  {item.joined ? (
                    <Text
                      style={{ fontSize: 14, marginLeft: 4, color: "grey" }}
                    >
                      {userDatabase[item.user_id].displayName} joined
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontSize: 16,
                        marginLeft: 6,
                        fontWeight: "500",
                      }}
                    >
                      {userDatabase[item.user_id].displayName}
                    </Text>
                  )}
                </View>
                {item.content ? (
                  <View style={styles.content}>
                    <Markdown
                      style={StyleSheet.create({
                        text: { fontSize: 16, marginLeft: 2 },
                      })}
                      rules={{
                        html: () => null, // Disable HTML rendering
                      }}
                    >
                      {item.content}
                    </Markdown>
                  </View>
                ) : // <br />
                null}
              </View>
            )}
          />
          {!isAtBottom && (
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: "center",
                flexDirection: "row",
                marginBottom: 30,
                backgroundColor: "transparent",
                paddingLeft: (Dimensions.get("window").width - 750) / 2 - 250,

                paddingRight: (Dimensions.get("window").width - 750) / 2,
              }}
              onPress={() => {
                scrollToEnd(0, true);
              }}
            >
              {/* Replace "Scroll to bottom" with your SVG */}
              <Icon.ArrowDown />
            </TouchableOpacity>
          )}

          <View style={styles.inputContainer}>
            <textarea
              ref={textareaRef}
              style={{
                fontSize: 15,
                flex: 1,
                outlineStyle: "none",
                marginRight: 8,
                borderColor: "#dddddd",
                backgroundColor: "#dddddd",
                borderWidth: 1,
                borderRadius: 16,
                padding: 8,
                minHeight: 36,
                maxHeight: 180,
                overflow: "auto", // add this
                height: "auto",
                resize: "none", // add this to disable manual resizing
              }}
              placeholder="Message"
              value={inputMessage}
              onChange={(e) => onChangeText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleMessageSend();
                }
              }}
            />

            <TouchableOpacity
              onPress={handleMessageSend}
              disabled={sendDisabled}
              style={styles.sendButton}
            >
              {sendDisabled ? (
                <Icon.ArrowUpCircle
                  fill="gray"
                  stroke="#f2f2f2"
                  strokeWidth={1.5}
                  width={34}
                  height={34}
                />
              ) : (
                <Icon.ArrowUpCircle
                  fill="black"
                  stroke="#f2f2f2"
                  strokeWidth={1.5}
                  width={34}
                  height={34}
                />
              )}
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ChatContext.Provider>
  );
};
const styles = StyleSheet.create({
  channelsBar: {
    backgroundColor: "#f5f5f5",
    // height: "100%",
    // flex: 1,
    paddingHorizontal: 6,
    paddingVertical: 10,

    // paddingBottom: 800,
  },
  iconContainer: {
    paddingRight: 8,
    // width: 24,
    // alignItems: "center",
  },
  channelsHeader: {
    fontSize: 18,
    fontWeight: "bold",
    paddingBottom: 20,
    paddingTop: 8,
    paddingHorizontal: 15,
    color: "#333",
    // borderBottomWidth: 1,
    // borderBottomColor: "#e0e0e0",
  },
  channel: {
    paddingHorizontal: 15,
    flexDirection: "row",

    paddingTop: 4,
    paddingBottom: 6,
    marginVertical: 2,
    // borderBottomWidth: 1,
    // borderBottomColor: "#e0e0e0",
  },
  activeChannel: {
    backgroundColor: "#ddd",
    borderRadius: 5,
  },
  channelTitle: {
    fontSize: 16,
    // fontWeight: "400",
    color: "grey",
  },
  activeChannelTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "black",
  },
  iconsChannels: {
    alignItems: "center",
  },
  sendButton: {
    width: 27,
  },
  container: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
    // height: "100%", // Ensure the container takes up full height
  },
  flatList: {
    //width: "40%", // Make the list take up the full width of its container
    // alignSelf: "center",
    paddingLeft: (Dimensions.get("window").width - 750) / 2 - 250,

    paddingRight: (Dimensions.get("window").width - 750) / 2,
    minWidth: 750,
    marginLeft: 16,
    // paddingHorizontal: "30%",
    // marginHorizontal: "auto", // Add this line
  },
  content: {
    marginHorizontal: 30,
  },
  messageHeader: {
    flexDirection: "row",
    alignItems: "center",
    margin: 6,
  },
  avatar: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginTop: 4,
    // marginRight: 6,
    // marginLeft: 4,
    // marginBottom: 1,
  },

  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    //paddingHorizontal: 16,
    // paddingVertical: 12,
    //paddingTop: 30,
    marginBottom: 20,
    marginHorizontal: 10,
    paddingLeft: (Dimensions.get("window").width - 750) / 2 - 250,

    paddingRight: (Dimensions.get("window").width - 750) / 2,
  },
  sidebarContainer: {
    width: 250,
    borderRightWidth: 1,
    borderRightColor: "#e0e0e0",
    // flex: 1,
  },
  chatContainer: {
    flex: 1,

    // flexDirection: "column",
  },
  input: {
    fontSize: 15,
    flex: 1,
    // outlineStyle: "none",
    marginRight: 8,
    borderColor: "#dddddd",
    backgroundColor: "#dddddd",
    borderWidth: 1,
    borderRadius: 16,
    padding: 8,
    height: 40, // Set your desired max height here
  },
});

export default DesktopChat;
