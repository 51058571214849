import React, { useState, useEffect, useContext } from "react";
import { NavigationContainer } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import utils from "./utils"; // Import your utils module
import { isMobile } from "react-device-detect";
import { NavigationContainerRef } from "@react-navigation/native";
import { MobileBottomTabParams } from "../Navigation/navigationTypes";
import { View, Image, TouchableOpacity, StyleSheet, Text } from "react-native";
import { useNavigation, useFocusEffect } from "@react-navigation/native"; // Import useNavigation
import { DesktopMainStackNavigationProp } from "../Navigation/navigationTypes";
import * as Icon from "react-native-feather";
import { useRoute } from "@react-navigation/native";
import { useNavigationState } from "@react-navigation/native";
import { changeUrl } from "../App";
import { AuthContext } from "../Auth/auth-context";

const SideBar: React.FC = React.memo(() => {
  const { isLoggedIn, isLoading } = useContext(AuthContext); // Get the user image and isUserLoggedIn state from the UserContext
  if (!isLoggedIn && !isLoading) {
    return null;
  }

  const navigation = useNavigation<DesktopMainStackNavigationProp>();
  const routeName = useNavigationState((state) => {
    return state?.routes[state?.index]?.name;
  });
  const handleHomePress = () => {
    changeUrl("");
    navigation.navigate("Home");
  };

  const handleChatPress = () => {
    changeUrl("chat");
    navigation.navigate("Chat");
  };
  const handleResearchPress = () => {
    changeUrl("research");
    navigation.navigate("Research");
  };
  const handleAIChatPress = () => {
    changeUrl("ai");
    navigation.navigate("AI");
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.icon} onPress={handleHomePress}>
        {routeName === "Home" ? (
          <Icon.Home color="black" strokeWidth={1.8} />
        ) : (
          <Icon.Home color="grey" strokeWidth={1.2} />
        )}
        {routeName === "Home" ? (
          <Text style={styles.highlightedText}>Home</Text>
        ) : (
          <Text style={styles.text}>Home</Text>
        )}
      </TouchableOpacity>
      <TouchableOpacity style={styles.icon} onPress={handleChatPress}>
        {routeName === "Chat" ? (
          <Icon.MessageCircle color="black" strokeWidth={1.8} />
        ) : (
          <Icon.MessageCircle color="grey" strokeWidth={1.2} />
        )}
        {routeName === "Chat" ? (
          <Text style={styles.highlightedText}>Chat</Text>
        ) : (
          <Text style={styles.text}>Chat</Text>
        )}
      </TouchableOpacity>

      <TouchableOpacity style={styles.icon} onPress={handleResearchPress}>
        {routeName === "Research" ? (
          <Icon.Layers color="black" strokeWidth={1.8} />
        ) : (
          <Icon.Layers color="grey" strokeWidth={1.2} />
        )}
        {routeName === "Research" ? (
          <Text style={styles.highlightedText}>Research</Text>
        ) : (
          <Text style={styles.text}>Research</Text>
        )}
      </TouchableOpacity>
      {/* <TouchableOpacity style={styles.icon} onPress={handleAIChatPress}>
        {routeName === "AI" ? (
          <Icon.Disc color="black" strokeWidth={2} />
        ) : (
          <Icon.Disc color="grey" strokeWidth={1.5} />
        )}
        <Text style={styles.text} numberOfLines={1}>
          AI
        </Text>
      </TouchableOpacity> */}
    </View>
  );
});
const styles = StyleSheet.create({
  container: {
    // position: "absolute", // Add this line

    // top: 0, // Add this line
    // left: 0, // Add this line
    // right: 0, // Add this line
    height: "100%", // Set the height of the top bar
    backgroundColor: "#f8f8f8", // Set the background color of the top bar
    width: 74, // Make the container span the entire width
    alignItems: "center",
  },
  icon: { padding: 15, paddingHorizontal: 20, alignItems: "center" },
  highlightedText: {
    fontFamily: "ComfortaaMed",
    fontWeight: "500",
    color: "black",
    fontSize: 10,
    paddingTop: 8,
    paddingHorizontal: 20,
  },

  text: {
    color: "grey",
    fontFamily: "ComfortaaMed",
    fontWeight: "500",
    fontSize: 10,
    paddingTop: 8,
    paddingHorizontal: 20,
  },
});

export default SideBar;
