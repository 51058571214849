import React, { useState } from "react";
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  Linking,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { isMobile } from "react-device-detect";
import { DOMAIN_BASE_URL } from "../Misc/utils";
import {
  DesktopMainStackNavigationProp,
  MobileMainHomeNavigationProp,
} from "../Navigation/navigationTypes";
import { changeUrl } from "../App";

interface ResearchTopic {
  id: string;
  title: string;
  description: string;
  routeName: string;
}

const ResearchPage = () => {
  const isMobileOS =
    Platform.OS === "ios" || Platform.OS === "android" || isMobile;
  const styles = isMobileOS ? mobileStyles : desktopStyles;
  const mobileNavigation = useNavigation<MobileMainHomeNavigationProp>();
  const desktopNavigation = useNavigation<DesktopMainStackNavigationProp>();

  const [researchTopics] = useState<ResearchTopic[]>([
    {
      id: "1",
      title: "PC Agent",
      description: "Improving current AI for real-life use cases",
      routeName: "PCAgent",
    },
  ]);
  const handleTopicPress = (routeName: string) => {
    if (routeName == "PCAgent") {
      changeUrl("research/pc-agent");

      isMobileOS
        ? mobileNavigation.navigate("PCAgentPage")
        : desktopNavigation.navigate("PCAgentPage");
    }
  };

  const renderResearchTopic = ({ item }: { item: ResearchTopic }) => (
    <TouchableOpacity
      style={styles.topicContainer}
      onPress={() => handleTopicPress(item.routeName)}
    >
      <Text style={styles.topicTitle}>{item.title}</Text>
      <Text style={styles.topicDescription}>{item.description}</Text>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.header}>Research Topics</Text>
      <FlatList
        data={researchTopics}
        renderItem={renderResearchTopic}
        keyExtractor={(item) => item.id}
        contentContainerStyle={styles.listContent}
      />
    </View>
  );
};

const baseStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: "#f0f0f0",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  listContent: {
    flexGrow: 1,
  },
  topicContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 20,
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  topicTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 5,
  },
  topicDescription: {
    fontSize: 14,
    color: "#666",
  },
});

const mobileStyles = StyleSheet.create({
  ...baseStyles,
  container: {
    ...baseStyles.container,
    paddingTop: 40, // Accounting for mobile status bar
  },
  topicContainer: {
    ...baseStyles.topicContainer,
    marginHorizontal: 10,
  },
});

const desktopStyles = StyleSheet.create({
  ...baseStyles,
  container: {
    ...baseStyles.container,
    maxWidth: 800,
    marginHorizontal: "auto",
  },
  listContent: {
    ...baseStyles.listContent,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  topicContainer: {
    ...baseStyles.topicContainer,
    width: "50%",
    marginBottom: 20,
  },
});

export default ResearchPage;
