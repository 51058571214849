import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from "react-native";
import utils, { InteractionManager } from "../Misc/utils"; // Import your utility module
import { CellType } from "./Cell";
import { useNavigation } from "@react-navigation/native"; // Import useNavigation
import {
  DesktopMainStackNavigationProp,
  MobileMainHomeNavigationProp,
} from "../Navigation/navigationTypes";
import { isMobile } from "react-device-detect";
import { changeUrl } from "../App";

const interactionManager = InteractionManager.getInstance();

const MiniCell: React.FC<CellType> = React.memo(({ cell, source }) => {
  const isMobileOS =
    Platform.OS === "ios" || Platform.OS === "android" || isMobile;
  const mobileNavigation = useNavigation<MobileMainHomeNavigationProp>();
  const desktopNavigation = useNavigation<DesktopMainStackNavigationProp>();

  const { id, title, imageUrl } = cell;
  const handlePress = () => {
    const source = "mini_cell";
    changeUrl(`posts/${cell.id}`);
    isMobileOS
      ? mobileNavigation.navigate("InnerCell", { cell, source })
      : desktopNavigation.navigate("InnerCell", { cell, source });
    interactionManager.add({
      post_id: id,
      type: "post_click",
      data: { source: source },
    });
  };
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={handlePress}
      style={styles.container}
    >
      <View style={styles.textContainer}>
        <Text style={styles.title} numberOfLines={2} ellipsizeMode="tail">
          {title}
        </Text>
      </View>

      <Image source={{ uri: imageUrl }} style={styles.image} />
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 12,
    borderBottomWidth: 1,
    borderColor: "#ccc",
    backgroundColor: "white",
  },
  textContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "ComfortaaMed",
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 8,
  },
});

export default MiniCell;
